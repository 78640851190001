<button class="btn btn-link text-decoration-none btn-block text-white mt-4" (click)="dashboard()">
  <i class="bx bxs-pie-chart-alt-2"></i>
  Admin Area
</button>
<button class="btn btn-link text-decoration-none btn-block text-white mt-4" (click)="goToRegistration()">
  <i class='bx bx-buildings'></i>
  Registration
</button>

<button class="btn btn-link text-decoration-none btn-block text-white mt-4" (click)="goToPortal()">
  <i class='bx bxs-bank'></i>
  Subscription Portal
</button>
