export interface TenantI {
  name: string;
  id: string;
  slug: string;
  status: string;
  domain: string;
  plan: string;
  logo: string;
  primaryColor: string;
  secondaryColor: string;
  subscriptionId: string;
  trialEndsAt: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;

  users: TenantUsersI[];
}

export interface TenantUsersI {
  id: string;
  tenantId: string;
  profileId: string;
  auth0Id: string;
  role: string;
  emailAddress: string;
}

export interface TenantResponse {}
export interface PricingPlanI {
  id: string;
  name: string;
  price: number;
  features: string[];
  buttonText: string;
  popular?: boolean;
}
