import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const tenantId = localStorage.getItem('tenantId'); // Example: fetch from storage or a service

    if (tenantId) {
      // Clone the request to add tenantId to headers
      const clonedRequest = request.clone({
        setHeaders: {
          'X-Tenant-ID': tenantId,
        },
      });
      return next.handle(clonedRequest);
    }


    return next.handle(request); // No tenantId, proceed with original request
  }
}
