import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ENV } from '@bodyanalytics/app-config';
import { RouterUrlsEnum } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-admin-member-button',
  templateUrl: './admin-button.component.html',
  styleUrls: ['./admin-button.component.scss'],
})
export class AdminMemberButtonComponent {
  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENV) private appConfig: any,
  ) {}
  dashboard() {
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_DASHBOARD}`);
  }
  goToPortal() {
    this.document.location.href = `${this.appConfig.stripePlanUrl}`;
  }

  goToRegistration() {
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_REGISTER}`);
  }
}
