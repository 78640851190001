export interface UserDetailsI {
  id: string;
  emailAddress: string;
}
export interface LicenseI {
  id: string;
  goal: string;
  date: string;
}

export interface LicenseDetailsI {
  licenseKey: string;
  tenantId: string;
}
